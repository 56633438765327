<template>
    <div class="epg-channel-wrapper" >
        <!--previous-arrow-->
        <!-- <div class="w-100 d-none" v-if="channelData.length > 6">
            <button type="button" class="up-down-arrow up-arrow" @click="actUpDownArrow('up')">
                <img src="@/assets/icons/less-than-filled-50.png" width="30px" alt="less" />
            </button>
        </div> -->
        <div class="cast-user-container slider-container" >
            <ul class="user-list slider-content">
                <li class="user-item hand" :class="[selectedChannelIndex == i ? 'active' : '']" v-for="(item, i) in channelsData" :key="i" @click="actSelectChannel(item, i)">
                    <div class="user-img">
                        <img  :src="placeholder_lands" :data-src="getImageSrc(item.icon.src)"  width="68" class="cast-image" :alt="item['display-name']" @error="actLoadError" data-lazy-load/>
                    </div>
                    <div class="cast-name" v-html="item['display-name']"></div>
                </li>
            </ul>
        </div>
        <!--next-arrow-->
        <!-- <div class="w-100 d-none" v-if="channelData.length > 6">
            <button type="button" class="up-down-arrow down-arrow" @click="actUpDownArrow('Down')">
                <img src="@/assets/icons/less-than-filled-50.png" width="30px" alt="less" />
            </button>
        </div> -->
    </div>
</template>
<script>
    import { observerInterSection } from "@/modules/observerInterSection";
    export default {
        name: 'ChannelContainer',
        props: ['channelsData'],
        data() {
            return {
                selectedChannelIndex: 0,
                placeholder_lands: require('@/assets/placeholder-lands.svg'),
            }
        },
        mounted() {
            observerInterSection();
        },
        methods: {
            actSelectChannel(channel, i) {
                this.selectedChannelIndex = i;
                this.$emit('actSelectedChannel', channel);
            },        
            getImageSrc(src) {
                return src ? `${src}` : this.placeholder_lands;
            },
            actLoadError (e) {
                e.target.src = this.placeholder_lands;
            },
        }
    }
</script>
<style scoped lang="scss">
    @import "@/sass/_variables.scss";
    @import "@/sass/_components.scss";

    .epg-channel-wrapper {
        height: 100%;
        position: relative;
        .up-down-arrow {
            width: 100%;
            border: none;
            background: transparent;
            cursor: pointer;
            text-align: center;
            z-index: 9;
        }
        .up-arrow {
            position: absolute;
            top: 0;
            img {                        
                transform: rotate(90deg);
            }
        }
        .down-arrow {
            img{
                transform: rotate(270deg);
            }
            position: absolute;
            bottom: 0;
        }
        .cast-user-container {
            height: 100%;
            overflow: auto;
            .user-list {
                padding: 0.5rem;
                .user-item {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    height: 3.5rem;
                    font: 1.1rem;
                    padding: 0.5rem;
                    color: $clr-text;
                    border-radius: 3px;
                    background: #817c7c26;
                    .user-img{
                    display: flex;
                        align-items: center;
                        justify-content: center;
                        .cast-image{
                                // border-radius: 50%;
                                border-radius: 4px;
                                border: 2px solid #77777736;
                                background: #000000;
                                aspect-ratio: 16/12;
                        }
                    }
                }:hover:not(.active) {
                    background-color: #707070;
                    color: #fff !important;
                }
            }
        }

        .slider-container {
            position: relative;
            overflow: auto;
        }

        .slider-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 101%;
            display: flex;
            flex-direction: column;
            transition: transform 0.3s ease;
            overflow: auto;
            gap:10px;
        }
    }    
    .active { 
        background: $primary-btn-color !important;
        color: #FFFFFF !important;
    }
    .user-item:hover:not(.active) {
        background-color: black;
    }
    @media only screen and (max-width: 768px) {
        .epg-channel-wrapper {
            .cast-user-container {
                height: 100%;
                overflow: auto;
                .user-list {
                    .user-item {
                        flex-direction: column;
                        gap: 0.2rem !important;
                        font-size:0.7rem;
                        height: auto !important;
                        padding: 0.25rem;
                        .user-img {
                            .cast-image {
                                width: 100%;
                            }
                        }
                        .cast-name {
                            width: 95%;
                            word-break: break-all;
                        }
                    }
                }
            }
        }
}
</style>